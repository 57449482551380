@font-face {
  font-family: 'Clear Sans';
  src: url('./fonts/ClearSans-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Stymie Condensed';
  src: url('./fonts/OPTIStymie-BoldCondensed.otf') format('woff');
}
@font-face {
  font-family: 'Stymie Bold';
  src: url('./fonts/OPTIStymie-Bold.otf') format('woff');
}
@font-face {
  font-family: 'Stymie Open';
  src: url('./fonts/OPTIStymie-Open.otf') format('woff');
}

h3 {
  margin: .25rem;  
}

.header-container {
  max-width: 100vw;
  display: grid;
  justify-content: center; 
  align-items: center;
  padding: 1rem;
  display: flex;
  background-color: #ffffff;
  margin-bottom: 1rem;
  border-bottom: #787c7f 2px solid;
  align-items: center;
  z-index: 10000;
}

.footer-container {
  width: 100%;  /* Still using full viewport width */
  display: flex;
  font-family: 'Clear Sans';
  font-size: 1rem;
  justify-content: left;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  padding: 1rem;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Adjust minmax values as needed */
  grid-gap: 10px; /* Adjust gap size as desired */
}

.header-wrapper {
  z-index: 1000000;
}

.friend-add-container {
  justify-content: center;
  border-radius: .5rem;
  text-align: center;
  display: grid;
  z-index: 1000;
  padding: 1rem;
  border: 2px black solid;
}

.header-title-text {
  font-size: 3rem;
  color: black;
  font-weight: 1000;
  text-align: center;
  margin-top: 0;
  border-radius: .5rem;
  font-family: 'Stymie Condensed';
}

.menu-buttons-container {
  position: absolute;
  right: 0;
  margin-right: 1rem;
}

.main-menu-container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding: 2rem;
  border: 2px black solid;
  position: absolute;
  width: fit-content;
  height: fit-content;
  transform: translateY(-100vh); /* Initially hides the menu content off-screen */
  overflow-y: auto; /* Enables scrolling if content overflows */
  transition: transform 0.5s ease-in-out; /* Smooths menu appearance */
  z-index: 100; /* Places the menu above other elements */
  background-color: white;
  box-shadow: 5px 5px; /* Optional shadow */
  z-index: 100;
}

.main-menu-container.show {
  transform: translateY(0); /* Shows the menu content by translating it down */
}





.manage-friends-header {
  display: grid;
  width: 15rem;
  justify-content: center; 
  align-items: center;
  display: flex;
  background-color: #ffffff;
  margin-bottom: 1rem;
  border-bottom: #787c7f 2px solid;
}

.manage-friends-container {
  transform: translateY(-200%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  border: 2px black solid;
  position: absolute;
  width: fit-content;
  height: fit-content;
  overflow-y: auto; /* Enables scrolling if content overflows */
  transition: transform 0.5s ease-in-out; /* Smooths menu appearance */
  z-index: 100; /* Places the menu above other elements */
  background-color: white;
  box-shadow: 5px 5px; /* Optional shadow */
  z-index: 100;
}

.remove-friend-button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  margin: .5rem;
  min-width: fit-content;
  border-radius: .5rem;
  color: black;
  padding: .5rem;
  font-weight:900;
  font-family: 'Clear Sans';
}

.add-friend-input {
  font-family:'Courier New', Courier, monospace;
  text-align: center;
  height: 2rem;
  border: 2px black solid;
  margin: .5rem;
  margin-top: 1rem;
}

.friend-code-text {
  font-family: 'Clear Sans';
}

.menu-buttons-copy {
  background-color: black;
  font-size: 1.5rem;
  width: 100%;
  border: 2px black solid;
  color: white;
  padding: .5rem;
  font-weight: 700;
  font-size:1.2rem;
  font-family: 'Clear Sans';
}
.menu-buttons-add {
  background-color: black;
  margin: .5rem;
  min-width: fit-content;
  border: 2px black solid;
  color: white;
  padding: .5rem;
  font-family: 'Clear Sans';
}
.menu-buttons {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  margin: .5rem;
  min-width: fit-content;
  border-radius: .5rem;
  color: black;
  padding: .5rem;
  font-weight:900;
  font-family:sans-serif;
}

.menu-negative {
  overflow: hidden;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: vertical;
}

.bigger-input-container {
  overflow-x:hidden;
  overflow-y:hidden;
  transition: all .3s ease-in-out;
  position: fixed;
  bottom: 0;
  right: 0;
  max-height: 2rem;
  background-color: white;
  border: 2px black solid;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  display: grid;
  overflow: hidden;
  z-index: 100;
  margin: 1rem;
}

.top-input-container {
  text-align: center;
  color: white;
  font-family: 'Clear Sans';
  font-size: 1rem;
  background-color: black;
  border: none;
  padding: .35rem;
  margin-bottom: 1rem;
  margin-top: -.1rem;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  border: #3b3b3b 1px solid;
}

.bigger-input-container.show {
  max-height: 50%;
  border: 3px solid black;
}

.input {
  display: block;
  padding: 1rem;
  box-shadow: 5px 5px;
  height: 10rem;
  overflow: hidden;
  border: 2px black solid;
  text-align: center;
  justify-content: center;
  text-wrap: wrap;
  
}

.input-comment {
  display: block;
  margin-top:1rem;
  padding: 1rem;
  height: 1rem;
  overflow: hidden;
  border: 2px black solid;
  border-radius: .5rem;
  text-align: center;
  justify-content: center;
  text-wrap: wrap;
  margin-bottom: 1rem;

}

.submit-button {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  background-color: #FFD800;
  border: none;
  margin: 1rem;
  padding: 1rem;
  border: 2px black solid;
  border-radius: .5rem;

}

.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.register-card-container {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.create-user-container {
  font-family: 'Clear Sans';
  display: grid; 
  margin: .5rem;
  background-color: white;
  min-width: 20rem;
  min-height: 5rem;
  max-width: 20rem;
  border-top: 2px #787c7f solid;
  border-bottom: 2px #787c7f solid;
  border-left: 2px #787c7f solid;
  box-shadow: 5px 5px;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: .5rem;
  padding-bottom: 1rem;
}

.create-user-button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  font-family: 'Clear Sans';
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  color: white;
  background-color: black;
  
  max-width: fit-content;
}

.create-user-input {
  margin-top: .5rem;
  font-family:'Courier New', Courier, monospace;
  display: block;
  min-width: 15rem;
  padding: 1rem;
  overflow: hidden;
  border: 2px black solid;
  text-align: center;
  justify-content: center;
  margin-bottom: 1rem;
  
}

.create-user-text {
  color: black;
}

.return-user-container {
  display: grid; 
  background-color: #FFD800;
  min-width: 20rem;
  min-height: 5rem;
  border: 1px #FFD800 solid;
  border-radius: .5rem;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin: .5rem;
}

.return-user-input {
  font-family:'Courier New', Courier, monospace;
  display: block;
  margin-top: .5rem;
  padding: 1rem;
  overflow: hidden;
  border: 2px black solid;
  text-align: center;
  justify-content: center;
  margin-bottom: 1rem;
  
}

.return-user-submit {
  max-width: 6rem;
  margin: auto;
}

.emphasized-register-text {
  font-size: 2.5rem;
  color: red;
  text-decoration-line: underline;
}

.private-passkey {
  justify-content: center;
  text-align: center;
  color: black;
}

.app-wrapper {
  max-width: 100vw;
  max-height: 100vh;
}

.main-container {
  max-width: 100vw;
  min-height: 70vh;
  height: fit-content;
}

.score-container {
  scrollbar-width: none;
  background-color: white;
  max-width: 100%;
  overflow-x: auto;
  display: flex;
  padding: 1rem;
}

.bigger-comment-container {
  display: flex;
  max-width: 100vw;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-left: .5rem;
}

.comment-container {
  scrollbar-width: none;
  flex: 0 0 auto;
  max-width: 40vw;
  background-color: white;
  white-space: wrap;
  padding: .7rem;
  margin: .25rem;
  border: black 2px solid;
  border-radius: .5rem;
}

.comment-text {
  font-family:'Courier New', Courier, monospace;
  color: black;
}

.score-card {
  background-color: white ;
  border-bottom: 2px #787c7f solid;
  border-left: 2px #787c7f solid;
  border-top: 2px #787c7f solid;
  box-shadow: 5px 5px;
  margin-right: 2.5rem;
  max-width: fit-content;
  min-width: 10rem;
  padding: .25rem;
}

h2 {
  margin:0;
}

p {
  margin:0;
}

.score-card-name {
  font-family: 'Stymie Condensed';
  color: black;
  font-size: 1.7rem;
  width: fit-content;
  margin-top: .5rem;
  margin-left: .5rem;
  border-radius: .5rem;
}

.score-card-text {
  font-family:'Clear Sans';
  padding-bottom: 1rem;
}

.game-title {
  font-size: 1.25rem;
  color: black;
  margin-left: 3rem;
  font-family: 'Clear Sans';
  border-bottom: 2px solid #787c7f;
}

.game-row-container {
  padding-bottom: 1rem;
  max-width: 100vw;
  overflow-x: auto;
  text-align: center;
}

.buy-me-a-coffee {
  text-decoration-line: none;
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
  background-color: #FFD800;
  border: 2px black solid;
  border-radius: .5rem;
  color: black;
  font-family: 'Clear Sans';
}

.board-game-button {
  text-decoration-line: none;
  font-size: 1.2rem;
  text-align: center;
  background-color: #FFD800;
  border: 2px black solid;
  border-radius: .5rem;
  color: black;
  font-family: 'Clear Sans';
  cursor: pointer;
}

.log-out-button {
  text-decoration-line: none;
  width: 100%;
  font-size: 1.2rem;
  background-color: #787c7f;
  border: 2px black solid;
  color: white;
  padding: 1rem;
  font-family: 'Clear Sans';
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.manage-friends-button {
  text-decoration-line: none;
  width: 100%;
  font-size: 1rem;
  background-color: #787c7f;
  border: 2px black solid;
  color: white;
  padding: .5rem;
  font-family: 'Clear Sans';
  margin-top: .5rem;
}

.coffee-container {
  display: flex;
  justify-content: left;
  text-align: center;
}

.cancel-area-container {
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 45;
}

.cancel-area-container.show {
  visibility: visible;
}

.cancel-area {
  position:fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.calendar-container {
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  width: fit-content;
  text-align: center;
  border-bottom: #787c7f 2px solid;
  border-left: #787c7f 2px solid;
  margin-bottom: 1rem;
  padding: 1rem;
}

.date-button {
  background: none;
	color: inherit;
	border: none;
	padding-right: .5rem;
  padding-left: .5rem;
	font: inherit;
	cursor: pointer;
  font-size: 1.15rem;
	outline: inherit;
  font-family: 'Clear Sans';
  font-weight: bolder;
  justify-content: center;
}
.calendar-buttons {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  vertical-align: middle;
  transform: scale(1.25);
  color: black;
  font-family: 'Clear Sans';
}

.no-data-text-container {
  margin-top: 3rem;
  display: flex;
  font-size: x-large;
  font-family: 'Clear Sans';
  text-align: center;
  justify-content: center;
  max-width: 25%;
  margin-left: auto;
  margin-right: auto;
}

.logged-in-as-text {
  font-family: 'Clear Sans';
}

.bigger-board-game-container {
  scrollbar-width: 0;
  z-index: 100;
  background-color: white;
  position:fixed;
  transition: all .5s ease-in-out;
  top:20vh;
  left:0;
  transform: translateY(-100vh);
  width: 100%;
  border-top: black solid 5px;
  border-bottom: black solid 5px;
  overflow-x: auto;
}

.bigger-board-game-container.show {
  transform: translateY(0);
}

.board-game-container {
  display: flex;
  flex-wrap: nowrap;
  scrollbar-width: 0;
  z-index: 50;
  padding-bottom: 1rem;
  width: fit-content;
  height: 55vh;
  overflow-x:auto;
  text-align: center;
}

.board-game-product {
  background-color: pink;
  max-height: 85%;
  padding: 1rem;
  margin: 2rem;
  overflow-y:scroll;
  scrollbar-width: 0;
  background-color: white ;
  border-bottom: 2px #787c7f solid;
  border-left: 2px #787c7f solid;
  border-top: 2px #787c7f solid;
  box-shadow: 5px 5px;

}

.board-game-link-container {
  cursor: pointer;
}

.board-game-title {
  font-size: 2rem;
  color: black;
  font-family: 'Stymie Condensed';
}

.board-game-description {
  margin-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-wrap:wrap;
  font-family: 'Clear Sans';
}

.board-game-image {
  width: 300px;
}

.secondary-board-game-container {
  padding-top: 1rem;
  max-width: 25rem;
  scrollbar-width: 0;
}

.forgot-password-button {
  background: none;
	color: inherit;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  text-decoration: underline;
  padding: .75rem;
  margin-top: .25rem;
}